import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
};

const ImageCarusel = () => (
  <div className='carusel'>
    <Slider {...settings} className='overflow-hidden'>
      <img src={require('./IMG_2260.png')} />
      <img src={require('./IMG_2263.png')} />
      <img src={require('./IMG_2264.png')} />
      <img src={require('./2265.png')} />
      <img src={require('./2266.png')} />
    </Slider>
  </div>
);

export default ImageCarusel;
