import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ImageCarusel from '../../../components/carusel/ImageCarusel';
import Layout from '../../../components/Layout';

const Exhibit = ({ data }) => (
  <Layout>
    <section className='jordenbrenner'>
      <h1>jorden brenner og ingen bryr seg</h1>
      <h2>
        <Link to='/work'>2020/exhibit</Link>
      </h2>

      <Img
        fluid={data.image1.childImageSharp.fluid}
        alt='man on ground'
        className='topimage'
      />

      <div className='mid-section'>
        <div className='box'>
          <img
            src={require('../../../images/utstilling/utstilling.png')}
            alt='stand'
          />
          <p>
            This exhibition is a continuation of a former project with the same
            name.
            <br />
            <br />
            Built to look like a voting booth, it consist of demonstration like
            posters that captures the feelings of the interview subjects. It
            also gives the viewer a chance to go inside and figure out for them
            selves what climate change means to them.
          </p>
        </div>

        <ImageCarusel />
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query jorden2images {
    image1: file(relativePath: { eq: "utstilling/1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Exhibit;
